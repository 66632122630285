import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Faq } from '../../store/Faq/faq.models';

@Injectable({ providedIn: 'root' })
export class FaqService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {}

  getFaqs(
    pageIndex: number,
    pageSize: number,
    searchTerm: any
  ): Observable<any> {
    let url =
      `${AppConfigData.getFaqsUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}`;
    return this.http.get<any>(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  createFaqs(faq: Faq): Observable<any> {
    // debugger;
    const formData = new FormData();
    faq.Title && formData.append('Title', faq.Title);
    faq.Content && formData.append('Content', faq.Content);
    if (faq.Files && faq.Files.length > 0) {
      const rawFiles = faq.Files.map((file: any) => file.rawFile);
      rawFiles.forEach((file: File) => {
        formData.append('Files', file);
      });
    }

    return this.http.post<any>(AppConfigData.createFaqsUrl, formData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  updateFaqs(faq: Faq, id: string): Observable<any> {
    // console.log(faq.Files.map((file: any) => file.rawFile));
    // if (faq?.Files.length > 0) {
    //   faq?.Files = faq?.Files.map((file: any) => file.rawFile);
    // }
    const formData = new FormData();
    faq.Title && formData.append('Title', faq.Title);
    faq.Content && formData.append('Content', faq.Content);
    if (faq.Files && faq.Files.length > 0) {
      const rawFiles = faq.Files.map((file: any) => file.rawFile);
      rawFiles.forEach((file: File) => {
        formData.append('Files', file);
      });
    }
    if (faq.FilesToDelete && faq.FilesToDelete.length > 0) {
      faq.FilesToDelete.forEach((fileId: number) => {
        formData.append('FilesToDelete', fileId.toString());
      });
    }

    return this.http.post<any>(AppConfigData.updateFaqsUrl + id, formData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  deleteFaqs(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteFaqsUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  downloadImage(file: any) {
    const headers = new HttpHeaders({
      'No-Intercept': 'true', // Custom header to skip interceptor
    });
    this.http
      .get(file.files[0].fileUrl, { headers, responseType: 'blob' })
      .subscribe(
        (blob) => {
          // Create a link element
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file.files[0].fileName; // Set the download filename

          // Append link to the body, click it, and then remove it
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          console.error('Error downloading the image: ', error);
        }
      );
  }

  downloadZip(id: string) {
    const headers = new HttpHeaders({
      // 'No-Intercept': 'true', // Custom header to skip interceptor
    });
    this.http
      .get(AppConfigData.downloadFaqsImagesZipUrl + id, {
        headers,
        responseType: 'blob',
      })
      .subscribe(
        (blob) => {
          // Create a link element
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'CoverImage.zip'; // Set the download filename

          // Append link to the body, click it, and then remove it
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        (error) => {
          console.error('Error downloading the image: ', error);
        }
      );
  }
}
